import { GET_BOX_WEEKLY_BASKET_RESPONSE } from 'actions/sendABox/boxWeeklyBaskets';
import { UPDATE_BOX_WEEKLY_BASKET_RESPONSE } from 'actions/sendABox';

const initialState = {};

const boxBasketSuccess = (state, boxHashId, boxResponse) => {
  const { boxWeeklyBasket } = boxResponse;
  const { box, recipient, weeklyBasketId } = boxWeeklyBasket;

  const updated = {
    ...box,
    recipient: recipient,
    weeklyBasketId,
  };

  return {
    ...state,
    [boxHashId]: updated,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BOX_WEEKLY_BASKET_RESPONSE:
      return boxBasketSuccess(state, action.params.boxId, action.response);
    case UPDATE_BOX_WEEKLY_BASKET_RESPONSE:
      return boxBasketSuccess(state, action.params.boxId, action.response);
    default:
      return state;
  }
};

export const selectBoxBasket = (state, boxHashId) => state[boxHashId] || {};

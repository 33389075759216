import * as Amplitude from 'services/amplitude';
import * as Helpers from 'helpers/analytics';
import { triggerUsabillaCampaign } from 'helpers/triggerUsabillaCampaign';
import { TEMPO } from 'lib/constants';

import {
  ADD_BASKET_RESPONSE,
  UPDATE_BASKET_RESPONSE,
  UNDONATE_BASKET_RESPONSE,
  DONATE_BASKET_RESPONSE,
  SKIP_BASKET_RESPONSE,
  UNSKIP_BASKET_RESPONSE,
  UPDATE_BASKET_ADDRESS_RESPONSE,
} from 'actions/baskets';
import {
  UPDATE_MEAL_FILTER,
  DASHBOARD_CLEAR_ALL_FILTERS,
  CLEAR_MEAL_FILTER,
} from 'actions/mealFilters';
import {
  ADD_MEAL,
  REMOVE_MEAL,
  UNDO_BASKET_EDITS,
  CHANGE_MEAL_OPTION,
  UPDATE_MEAL_SERVINGS,
} from 'actions/basketEdits';
import { BOOTSTRAP_COOKBOOK } from 'actions/cookbook';
import { BOOTSTRAP_DASHBOARD } from 'actions/dashboard';
import { ADD_FAVORITE_RESPONSE, REMOVE_FAVORITE_RESPONSE } from 'actions/favorites';
import { GET_MEALS_REQUEST } from 'actions/meals';
import { BOOTSTRAP_MESSAGE_CENTER } from 'actions/messageCenter';
import { ADD_REVIEW_RESPONSE, ADD_REVIEW_FOR_COOKBOOK_RESPONSE } from 'actions/reviews';
import { ROUTE_CHANGE } from 'actions/routes';
import { MESSAGE_INTERACTION } from 'actions/messageCenter';
import {
  UPDATE_USER_RESPONSE,
  GET_USER_RESPONSE,
  UNPAUSE_USER_RESPONSE,
  PAUSE_USER_REQUEST,
  SEND_INVITATION_RESPONSE,
  UPDATE_PAYMENT_RESPONSE,
  UPDATE_PASSWORD_RESPONSE,
  UPDATE_MEAL_ADDON_SELECTIONS_RESPONSE,
  UPDATE_OPT_IN_SELECTIONS_RESPONSE,
} from 'actions/user';
import { UPDATE_TASTE_PROFILE_RESPONSE } from 'actions/tasteProfile';
import { SET_FAVORITE } from 'actions/searchParams';
import {
  CREATE_ADDRESS_RESPONSE,
  DESTROY_ADDRESS_RESPONSE,
  UPDATE_ADDRESS_RESPONSE,
  SET_DEFAULT_ADDRESS_RESPONSE,
} from 'actions/addresses';

import { selectMeal as selectCookbookMeal } from 'reducers/cookbook';
import { selectBasket, selectMeal, selectMealOptionGroup } from 'reducers/dashboard';
import { selectRestartCampaign } from 'reducers/restartCampaign';

const handleLogging = (store, action) => {
  switch (action.type) {
    case BOOTSTRAP_MESSAGE_CENTER:
    case BOOTSTRAP_COOKBOOK:
    case BOOTSTRAP_DASHBOARD:
      if (!Amplitude.isInitialized()) Amplitude.initialize();
      break;
    case MESSAGE_INTERACTION: {
      const amplitudeEventName = action.message.amplitudeEventName;
      if (amplitudeEventName) Amplitude.logEvent(action);
      break;
    }
    case GET_USER_RESPONSE:
      Amplitude.setAmplitudeUserProperties(action.response.user);
      break;
    case UNPAUSE_USER_RESPONSE:
      {
        const state = store.getState();
        const campaign = selectRestartCampaign(state);
        triggerUsabillaCampaign('reactivation', {
          UUID: state.user.uuid,
          numberOfBoxes: action.response.numberOfBoxes,
          mealPlan: state.user.mealPlanSlug,
        });
        Amplitude.logEvent(action, Helpers.buildReactivateCampaignEvent(campaign));
      }
      break;
    case UPDATE_USER_RESPONSE:
    case SEND_INVITATION_RESPONSE:
    case UPDATE_TASTE_PROFILE_RESPONSE:
    case PAUSE_USER_REQUEST:
    case UPDATE_PAYMENT_RESPONSE:
    case UPDATE_PASSWORD_RESPONSE:
    case UPDATE_MEAL_ADDON_SELECTIONS_RESPONSE:
    case UPDATE_OPT_IN_SELECTIONS_RESPONSE:
    case CREATE_ADDRESS_RESPONSE:
    case DESTROY_ADDRESS_RESPONSE:
    case UPDATE_ADDRESS_RESPONSE:
    case SET_DEFAULT_ADDRESS_RESPONSE:
    case UPDATE_BASKET_ADDRESS_RESPONSE:
      Amplitude.logEvent(action);
      break;
    case GET_MEALS_REQUEST: {
      const data = Helpers.buildSearchEvent(action);
      if (!data) break;
      Amplitude.logEvent(action, Helpers.buildSearchEvent(action));
      break;
    }
    case SKIP_BASKET_RESPONSE:
      {
        const state = store.getState();
        const basket = selectBasket(state, action.params.menuSlug);
        const triggerName =
          state.user.mealPlanSlug === TEMPO ? 'skip feedback tempo' : 'skip feedback';

        triggerUsabillaCampaign(triggerName, {
          skippedMenu: action.params.menuSlug,
          UUID: state.user.uuid,
          numberOfBoxes: action.response.numberOfBoxes,
          mealPlan: state.user.mealPlanSlug,
        });
        Amplitude.logEvent(action, Helpers.buildBasketEvent(basket));
      }
      break;
    case DONATE_BASKET_RESPONSE:
      {
        const state = store.getState();
        const basket = selectBasket(state, action.params.menuSlug);
        Amplitude.logEvent(action, Helpers.buildBasketEvent(basket));
      }
      break;
    case UNSKIP_BASKET_RESPONSE:
    case UNDONATE_BASKET_RESPONSE:
    case ADD_BASKET_RESPONSE:
    case UPDATE_BASKET_RESPONSE:
      Amplitude.logEvent(action, Helpers.buildBasketEvent(action.response.weeklyBasket));
      break;
    case UPDATE_MEAL_SERVINGS:
    case ADD_MEAL:
    case REMOVE_MEAL:
      {
        const meal = selectMeal(store.getState(), action.menuSlug, action.mealId);
        if (meal) Amplitude.logEvent(action, Helpers.buildMealEvent(meal));
      }
      break;
    case CHANGE_MEAL_OPTION:
      {
        const mealOptionGroup = selectMealOptionGroup(
          store.getState(),
          action.menuSlug,
          action.mealId,
          action.mealOptionGroupId
        );
        const mealOption = mealOptionGroup.mealOptions.find(
          option => option.id === action.mealOptionId
        );
        Amplitude.logEvent(action, Helpers.buildMealOptionEvent(mealOptionGroup, mealOption));
      }
      break;
    case UNDO_BASKET_EDITS:
      Amplitude.logEvent(action);
      break;
    case ADD_REVIEW_FOR_COOKBOOK_RESPONSE:
      {
        const meal = selectCookbookMeal(store.getState(), action.response.mealSelectionId);
        Amplitude.logEvent(action, Helpers.buildReviewEvent(action.response.recipeRating, meal));
      }
      break;
    case ADD_REVIEW_RESPONSE:
      Amplitude.logEvent(
        action,
        Helpers.buildReviewEvent(action.response.rating, action.response.meal)
      );
      break;
    case ADD_FAVORITE_RESPONSE:
      Amplitude.logEvent(action);
      break;
    case REMOVE_FAVORITE_RESPONSE:
      Amplitude.logEvent(action);
      break;
    case ROUTE_CHANGE:
      Amplitude.logEvent(action, Helpers.buildViewEvent(action));
      break;
    case DASHBOARD_CLEAR_ALL_FILTERS:
      if (action.shouldLog) {
        Amplitude.logEvent(action);
      }
      break;
    case CLEAR_MEAL_FILTER:
      Amplitude.logEvent(action);
      break;
    case UPDATE_MEAL_FILTER:
      Amplitude.logEvent(action, Helpers.buildFiltersObject(action));
      break;
    case SET_FAVORITE:
      Amplitude.logEvent(action);
      break;
  }
};

const middleware = store => next => action => {
  try {
    handleLogging(store, action);
  } catch (error) {
    // no-op
  }

  return next(action);
};

export default middleware;

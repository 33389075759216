import {
  GET_NSO_MENU_CATEGORIES_RESPONSE,
  GET_NSO_MENU_CATEGORIES_ERROR,
  GET_NSO_MENU_CATEGORIES_REQUEST,
} from 'actions/sendABox/menuCategories';

import { GET_NSO_UPCOMING_MENUS_RESPONSE } from 'actions/sendABox/menus';

import { STANDARD, FAMILY, TEMPO } from 'lib/constants';

import { backupMenuCategories } from 'lib/constants';

const backupState = backupMenuCategories;

const initialData = {
  loading: false,
  failed: false,
  data: [],
};

const initialState = {};

const mealPlans = [STANDARD, FAMILY, TEMPO];

const initializeMenuCategories = (currentData, menuSlugs) => {
  const menusCategoriesHash = menuSlugs.reduce((hash, slug) => {
    const mealPlanData = mealPlans.reduce((planHash, plan) => {
      return {
        ...planHash,
        [plan]: initialData,
      };
    }, {});
    return {
      ...hash,
      [slug]: mealPlanData,
    };
  }, {});

  return {
    ...menusCategoriesHash,
    ...currentData,
  };
};

const addMenuCategories = (
  currentData,
  menuSlug,
  mealPlan,
  menuCategories,
  loading = false,
  failed = false
) => {
  const nextState = {
    loading,
    failed,
    data: menuCategories,
  };

  return {
    ...currentData,
    [menuSlug]: {
      ...currentData[menuSlug],
      [mealPlan]: nextState,
    },
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NSO_UPCOMING_MENUS_RESPONSE:
      return initializeMenuCategories(
        state,
        action.response.sendABox.map(box => box.menu.slug)
      );
    case GET_NSO_MENU_CATEGORIES_REQUEST:
      return addMenuCategories(state, action.params.menuSlug, action.params.mealPlan, [], true);
    case GET_NSO_MENU_CATEGORIES_RESPONSE:
      return addMenuCategories(
        state,
        action.params.menuSlug,
        action.params.mealPlan,
        action.response.menuCategories,
        false,
        false
      );
    case GET_NSO_MENU_CATEGORIES_ERROR:
      return addMenuCategories(
        state,
        action.params.menuSlug,
        action.params.mealPlan,
        backupState,
        false,
        true
      );
    default:
      return state;
  }
};

export const selectMenuCategoriesLoading = (state, slug, mealPlan) => {
  return state[slug] && state[slug][mealPlan] ? state[slug][mealPlan].loading : initialData.loading;
};

export const selectMenuCategoriesData = (state, slug, mealPlan) => {
  return state[slug] && state[slug][mealPlan] ? state[slug][mealPlan].data : backupState;
};

import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';

import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import { isPantryIngredient, quantityForDisplay } from 'helpers/shoppingList';

import mealsReducer from './meals';
import servingsReducer from './servings';
import shoppingCartReducer from './shoppingCart';
import unselectedIngredientsReducer from './unselectedIngredients';

export default combineReducers({
  meals: mealsReducer,
  servings: servingsReducer,
  shoppingCart: shoppingCartReducer,
  unselectedIngredients: unselectedIngredientsReducer,
});

export const selectShoppingList = state => state.shoppingList;
export const selectUnselectedIngredientNames = createSelector(
  selectShoppingList,
  shoppingList => shoppingList.unselectedIngredients
);

export const selectMeals = createSelector(selectShoppingList, shoppingList => shoppingList.meals);

export const selectMealsCount = createSelector(selectMeals, meals => meals.length);

export const selectServings = createSelector(
  selectShoppingList,
  shoppingList => shoppingList.servings
);

export const selectShoppingCart = createSelector(
  selectShoppingList,
  shoppingList => shoppingList.shoppingCart
);

export const selectAllIngredients = createSelector(
  selectMeals,
  selectUnselectedIngredientNames,
  selectServings,
  (shoppingListMeals, unselectedIngredientNames, servings) => {
    const allIngredients = shoppingListMeals.flatMap(meal => {
      return meal.ingredients.map(ingredient => ({
        ...ingredient,
        meal,
        multiplier: (servings[meal.id] || 2) / 2,
      }));
    });

    const byName = groupBy(allIngredients, 'name');

    return Object.entries(byName).map(([name, ingredientInstances]) => {
      const firstInstance = ingredientInstances[0];
      const totalQuantity = sumBy(
        ingredientInstances,
        ingredientInstance => ingredientInstance.quantity * ingredientInstance.multiplier
      );
      const displayQuantity = quantityForDisplay(totalQuantity);
      return {
        name,
        measure: firstInstance.measure,
        pantryIngredient: isPantryIngredient(firstInstance),
        totalQuantity,
        displayQuantity,
        meals: ingredientInstances.map(instance => instance.meal),
        selected: !unselectedIngredientNames.includes(name),
      };
    });
  }
);

export const selectIngredientsCount = createSelector(
  selectAllIngredients,
  ingredients => ingredients.length
);

export const selectSelectedIngredients = createSelector(selectAllIngredients, allIngredients =>
  allIngredients.filter(ingredient => ingredient.selected)
);

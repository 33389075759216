import moment from 'moment';
import set from 'lodash/fp/set';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';

import {
  GET_MESSAGES_RESPONSE,
  REFRESH_MESSAGES_RESPONSE,
  UPDATE_MESSAGE_RESPONSE,
  EXPIRE_MESSAGE_RESPONSE,
} from 'actions/messageCenter';
import { createSelector } from 'reselect';

const defaultState = {
  promotions: [],
  userMessages: [],
  creditMessage: {},
};

const messageIndex = (messages, message) => findIndex(messages, m => m.id === message.id);

export default (state = defaultState, action) => {
  const { type, response } = action;
  switch (type) {
    case GET_MESSAGES_RESPONSE:
    case REFRESH_MESSAGES_RESPONSE:
      return {
        promotions: response.promotions,
        userMessages: response.userMessages,
        creditMessage: response.creditMessage,
      };
    case UPDATE_MESSAGE_RESPONSE:
      return set(
        ['userMessages', messageIndex(state.userMessages, response.message)],
        response.message,
        state
      );
    case EXPIRE_MESSAGE_RESPONSE:
      return {
        ...state,
        userMessages: filter(state.userMessages, m => m.id !== response.message.id),
      };
    default:
      return state;
  }
};

export const selectUserMessages = state => state.userMessages;
export const selectPromotions = state => state.promotions;
export const selectCreditMessage = state => state.creditMessage;

export const selectUserMessagesByPlacement = (state, placement) =>
  selectUserMessages(state).filter(message => message.placement === placement);

export const selectMessageByPlacementAndPriority = (state, placement) =>
  sortBy(selectUserMessagesByPlacement(state, placement), 'priority')[0];

export const selectDashboardMessages = state => {
  return selectUserMessagesByPlacement(state, 'dashboard').sort(
    (a, b) => moment(b.sentAt).valueOf() - moment(a.sentAt).valueOf()
  );
};

export const selectSingleDrawerMessages = createSelector(selectUserMessages, userMessages => {
  const filtered = userMessages.filter(
    message =>
      message.placement === 'drawer' && message.displayRule === 'one_message' && !message.readAt
  );
  return sortBy(filtered, message => new Date(message.expiredAt)).slice(0, 1);
});

export const selectDrawerMessages = createSelector(
  selectUserMessages,
  selectSingleDrawerMessages,
  (userMessages, singleMessages) =>
    userMessages
      .filter(message => message.placement === 'drawer' && message.displayRule !== 'one_message')
      .filter(message => message.name !== 'reward')
      .concat(singleMessages)
);

export const selectRewardMessage = createSelector(selectUserMessages, userMessages => {
  const activeBoxGoals = [2, 3];
  const rewardMessages = userMessages.filter(
    message => message.name === 'reward' && activeBoxGoals.includes(message.boxGoal)
  );

  return rewardMessages.length > 0 ? last(rewardMessages) : null;
});

export const pauseMessage = createSelector(selectUserMessages, userMessages => {
  return userMessages.find(
    message => message.name === 'pause' && message.showReactivationBanner === true
  );
});

export const selectUnreadDrawerMessages = createSelector(selectDrawerMessages, drawerMessages =>
  drawerMessages.filter(message => !message.readAt)
);

export const selectOrderedDrawerMessages = createSelector(selectDrawerMessages, drawerMessages =>
  sortBy(drawerMessages, message => new Date(message.sentAt))
);

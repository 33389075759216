import omit from 'lodash/omit';
import get from 'lodash/get';

import {
  GET_SHOPPING_LIST_MEAL_ERROR,
  SHOPPING_LIST_ADD_MEAL,
  SHOPPING_LIST_REMOVE_MEAL,
  SHOPPING_LIST_SET_SERVINGS,
} from 'actions/shoppingList';

import { LOCAL_STORAGE_LOAD } from 'actions/localStorage';

const defaultState = {};

const removeMeal = (state, mealId) => omit(state, mealId);

export default (state = defaultState, action) => {
  const { mealId, params, servings, type } = action;

  switch (type) {
    case LOCAL_STORAGE_LOAD:
      return {
        ...state,
        ...(get(action, 'data.shoppingList.servings') || {}),
      };
    case SHOPPING_LIST_ADD_MEAL:
      return {
        ...state,
        [mealId]: 2,
      };
    case SHOPPING_LIST_SET_SERVINGS:
      return {
        ...state,
        [mealId]: servings,
      };
    case GET_SHOPPING_LIST_MEAL_ERROR:
      return removeMeal(state, params.mealId);
    case SHOPPING_LIST_REMOVE_MEAL:
      return removeMeal(state, mealId);
    default:
      return state;
  }
};

/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import reducer from 'reducers';
import RequestService from 'services/RequestService';

import { getEnvConstants } from 'helpers/envConstants';
import { initializeFromLocalStorage } from 'actions/localStorage';
import analyticsMiddleware from 'middleware/analytics';
import errorLoggingMiddleware from 'middleware/errorLogging';
import messagesMiddleware from 'middleware/messages';
import localStorageMiddleware from 'middleware/localStorage';

export function configureStore(initialState) {
  const middlewares = [
    thunk.withExtraArgument({ requestService: RequestService }),
    errorLoggingMiddleware,
    analyticsMiddleware,
    messagesMiddleware,
    localStorageMiddleware,
  ];

  const { REDUX_LOGGING } = getEnvConstants();

  if (REDUX_LOGGING) {
    middlewares.push(logger);
  }

  const composeEnhancers =
    (REDUX_LOGGING && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const store = initialState
    ? createStore(reducer, initialState, composeEnhancers(applyMiddleware(...middlewares)))
    : createStore(reducer, composeEnhancers(applyMiddleware(...middlewares)));
  store.dispatch(initializeFromLocalStorage());
  return store;
}

export function getStore() {
  if (!window.homeChefStore) {
    window.homeChefStore = configureStore();
  }

  return window.homeChefStore;
}

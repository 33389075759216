import get from 'lodash/get';

import {
  CREATE_SHOPPING_CART_REQUEST,
  CREATE_SHOPPING_CART_RESPONSE,
  CREATE_SHOPPING_CART_ERROR,
  DISMISS_SHOPPING_CART,
} from 'actions/shoppingList';

const defaultState = {
  error: '',
  status: '',
  url: '',
};

export default (state = defaultState, action) => {
  const { type, response } = action;

  switch (type) {
    case CREATE_SHOPPING_CART_REQUEST:
      return {
        error: '',
        status: 'loading',
        url: '',
      };
    case CREATE_SHOPPING_CART_RESPONSE:
      return {
        error: '',
        status: 'success',
        url: response.url,
      };
    case CREATE_SHOPPING_CART_ERROR: {
      return {
        error: get(response, 'error.message', 'Unexpected error'),
        status: 'error',
        url: '',
      };
    }
    case DISMISS_SHOPPING_CART: {
      return {
        ...defaultState,
      };
    }
    default:
      return state;
  }
};

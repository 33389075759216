import get from 'lodash/get';

import {
  GET_SHOPPING_LIST_MEAL_RESPONSE,
  GET_SHOPPING_LIST_MEAL_ERROR,
  SHOPPING_LIST_ADD_MEAL,
  SHOPPING_LIST_REMOVE_MEAL,
} from 'actions/shoppingList';

import { LOCAL_STORAGE_LOAD } from 'actions/localStorage';

const defaultState = [];

const removeMeal = (state, mealId) => state.filter(meal => meal.id !== mealId);

export default (state = defaultState, action) => {
  const { mealId, params, response, type } = action;

  switch (type) {
    case LOCAL_STORAGE_LOAD:
      return [...state, ...(get(action, 'data.shoppingList.meals') || [])];
    case SHOPPING_LIST_ADD_MEAL:
      return [...state, { id: mealId, ingredients: [] }];
    case GET_SHOPPING_LIST_MEAL_RESPONSE:
      return state.map(meal => (meal.id === params.mealId ? response.shoppingListMeal : meal));
    case GET_SHOPPING_LIST_MEAL_ERROR:
      return removeMeal(state, params.mealId);
    case SHOPPING_LIST_REMOVE_MEAL:
      return removeMeal(state, mealId);
    default:
      return state;
  }
};
